import React from 'react'

const Triptracker = () => {
  return (
    <>
     <section>
     Triptracker Page
    </section>
 </>
  )
}

export default Triptracker
