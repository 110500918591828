import React from 'react'

const Signup = () => {
  return (
    <>
      <section>
        Signup Page
      </section>
    </>
  )
}

export default Signup
