import React from 'react'

const Planyourjourney = () => {
  return (
    <>
        <section>
        Planyourjourney Page
       </section>
    </>
  )
}

export default Planyourjourney
