import React from 'react'


const Homebanner = () => {
  return (
    <>
       <div class="banner">
        <div class="banner-content">
            <h1>Travel Smart</h1>
            <p>Experience seamless bus booking with our user-friendly platform. Find the best routes and enjoy a hassle-free journey.</p>
        </div>
        
    </div>
    </>
  )
}

export default Homebanner
