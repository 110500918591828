import React from 'react'

const Help = () => {
  return (
    <>
    <section>
    Help Page
   </section>
</>
  )
}

export default Help
