import React from 'react'
import './Thumbgallery.css';


const galleryData = [
    {
      id: 1,
      image: 'https://bustrip.jstechinfo.com/static/media/img1.png',
      location: 'New York',
      price: '$100',
      duration: '3 days'
    },
    {
      id: 2,
      image: 'https://bustrip.jstechinfo.com/static/media/img2.png',
      location: 'Paris',
      price: '$200',
      duration: '5 days'
    },
    {
        id: 3,
        image: 'https://bustrip.jstechinfo.com/static/media/img3.png',
        location: 'India',
        price: '$300',
        duration: '6 days'
      },
    // Add more items as needed
  ];
  

const Thumbgallery = () => {
  return (
    <>
        <div className="gallery-container">
                {galleryData.map(item => (
                <div className="gallery-item" key={item.id}>
                             <img src={item.image} alt={item.location} />
                        <div className="info">
                            <p><strong>Location:</strong> {item.location}</p>
                            <p><strong>Price:</strong> {item.price}</p>
                            <p><strong>Duration:</strong> {item.duration}</p>
                            <button className="submit-button">Submit</button>
                        </div>
                </div>
            ))}
            
         </div>
  
    </>
  )
}

export default Thumbgallery
