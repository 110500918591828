import React, { useState } from 'react';
import './Tabscomponent.css';

import Thumbgallery from './Thumbgallery';

const Tabscomponent = () => {
    const [activeTab, setActiveTab] = useState('tab1');

    const renderContent = () => {
        switch (activeTab) {
            case 'tab1':
                return <div className="content">
                            <h1>Popular Destination</h1>
                            <p>Explore the most sought-after destinations with our seamless bus booking service. Whether you're planning a weekend getaway or a long vacation, we have you covered. Check out these popular travel spots and book your tickets today!</p>
                            <Thumbgallery/>
                        </div>;
            case 'tab2':
                return <div className="content">
                      <h1>Latest offers</h1>
                        <p>Explore the most sought-after destinations with our seamless bus booking service. Whether you're planning a weekend getaway or a long vacation, we have you covered. Check out these popular travel spots and book your tickets today!</p>
                </div>;
            case 'tab3':
                return <div className="content">
                    <h1>Top  bus routes</h1>
                        <p>Explore the most sought-after destinations with our seamless bus booking service. Whether you're planning a weekend getaway or a long vacation, we have you covered. Check out these popular travel spots and book your tickets today!</p>
                </div>;
            default:
                return null;
        }
    };

    return (
        <div className="tabs-container">
            <div className="tabs">
                <button
                    className={`tab ${activeTab === 'tab1' ? 'active' : ''}`}
                    onClick={() => setActiveTab('tab1')}
                >
                    Popular destination
                </button>
                <button
                    className={`tab ${activeTab === 'tab2' ? 'active' : ''}`}
                    onClick={() => setActiveTab('tab2')}
                >
                    Latest offers
                </button>
                <button
                    className={`tab ${activeTab === 'tab3' ? 'active' : ''}`}
                    onClick={() => setActiveTab('tab3')}
                >
                    Top  bus routes
                </button>
            </div>
            <div className="tab-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default Tabscomponent;
