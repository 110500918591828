import React from 'react'

const Accessibility = () => {
  return (
    <>
        <section>
        Accessibility Page
       </section>
    </>
  )
}

export default Accessibility
