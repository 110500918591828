import React from 'react'
import './Whytravel.css';

const Whytravel = () => {
  return (
    <>
      <h1>Why Travel With Us</h1>

      <div className="layout-container">
            <div className="left-column">
            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="52" viewBox="0 0 46 52" fill="none">
                      <g clip-path="url(#clip0_0_129)">
                          <path d="M39.1949 50.3156C39.3257 50.6682 39.2865 51.0468 39.064 51.3537C38.8546 51.6605 38.5144 51.8368 38.1348 51.8368H12.1772C11.8043 51.8368 11.464 51.6605 11.2481 51.3537C11.0387 51.0403 10.9929 50.6682 11.1172 50.3156L14.5394 41.2997H35.7596L39.1883 50.3156H39.1949ZM44.2595 38.4924C43.2387 39.5109 41.8908 40.0723 40.4447 40.0723H12.5502C10.3844 40.0723 8.51295 38.6034 8.0091 36.5012L0.255194 4.33496C-0.163583 2.5853 0.863728 0.809536 2.60427 0.300308C2.92489 0.208908 3.25206 0.163208 3.57269 0.163208C4.92717 0.163208 6.1835 0.979278 6.71351 2.29805L13.2896 18.8023L14.4871 21.8119L17.0128 28.1381C17.8634 30.2664 20.0489 31.5786 22.3391 31.324L39.823 29.3394C41.3411 29.1631 42.8854 29.6527 44.0304 30.6712C45.1755 31.6896 45.8364 33.1586 45.8364 34.6928C45.8364 36.1356 45.2737 37.487 44.2529 38.4989L44.2595 38.4924Z" fill="#0F6CBD" />
                          <path d="M31.8074 17.0461C32.9983 16.9024 34.091 17.7577 34.235 18.9524C34.2481 19.0438 34.2481 19.1287 34.2481 19.2201C34.2481 20.3169 33.4236 21.2374 32.3243 21.368L16.3977 23.2743L15.6714 21.4463L14.7292 19.0895L31.8008 17.0395H31.8074V17.0461Z" fill="#3CAEA3" />
                      </g>
                      <defs>
                          <clipPath id="clip0_0_129">
                              <rect width="46" height="52" fill="white" />
                          </clipPath>
                      </defs>
                  </svg>
            </div>
            <div className="right-column">
                <p> Seat selection </p>
                <p>Your favourite sear every time</p>
            </div>
        </div>
        
      <div className="layout-container">
            <div className="left-column">
                  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
                      <g clip-path="url(#clip0_0_142)">
                          <path d="M20.4861 10.3002L0.779918 30.0065C0.383239 30.4032 0.168091 30.9276 0.168091 31.4923C0.168091 32.0571 0.396686 32.6084 0.806812 33.0185L4.29625 36.508C4.55846 36.7702 4.98203 36.7702 5.24424 36.508C7.24109 34.5111 10.4952 34.5111 12.492 36.508C14.4889 38.5048 14.4889 41.7589 12.492 43.7558C12.2298 44.018 12.2298 44.4416 12.492 44.7038L15.9815 48.1932C16.3849 48.5966 16.916 48.8252 17.4808 48.8319H17.5077C18.0657 48.8319 18.5969 48.6168 18.9868 48.2201L38.6931 28.5139L41.5505 25.6632L48.2201 18.9936C49.0404 18.1666 49.0269 16.8152 48.1932 15.9815L44.7038 12.4921C44.4416 12.2298 44.018 12.2298 43.7558 12.4921C42.7876 13.4602 41.5034 13.9914 40.1319 13.9914C38.7603 13.9914 37.4761 13.4602 36.508 12.4921C34.5111 10.4952 34.5111 7.2411 36.508 5.24426C36.7702 4.98204 36.7702 4.55847 36.508 4.29626L33.0185 0.806823C32.6151 0.40342 32.084 0.174825 31.5192 0.168102C30.9343 0.147932 30.4099 0.376526 30.0065 0.779929L23.3369 7.44952L20.4861 10.3002Z" fill="#0F6CBD" stroke="black" stroke-width="0.5" stroke-miterlimit="10" />
                          <path d="M31.7747 17.7901L31.2974 17.3127L31.7747 17.7901ZM24.6479 10.6566L24.1706 10.1792L24.6479 10.6566ZM29.4014 15.41L28.924 14.9326L29.4014 15.41ZM27.0213 13.0299L26.5439 12.5526L27.0213 13.0299ZM36.5349 22.5435L36.0575 22.0661L36.5349 22.5435ZM38.9082 24.9169L38.4309 24.4395L38.9082 24.9169ZM34.1548 20.1634L33.6774 19.6861L34.1548 20.1634ZM23.9218 18.8053L13.8569 28.8702L19.1885 34.2018L29.2534 24.1369L23.9218 18.8053Z" fill="#3CAEA3" />
                          <path d="M31.7747 17.7901L31.2974 17.3127M24.6479 10.6566L24.1706 10.1792M29.4014 15.41L28.924 14.9326M27.0213 13.0299L26.5439 12.5526M36.5349 22.5435L36.0575 22.0661M38.9082 24.9169L38.4309 24.4395M34.1548 20.1634L33.6774 19.6861M23.9218 18.8053L13.8569 28.8702L19.1885 34.2018L29.2534 24.1369L23.9218 18.8053Z" stroke="#3CAEA3" stroke-miterlimit="10" />
                      </g>
                      <defs>
                          <clipPath id="clip0_0_142">
                              <rect width="49" height="49" fill="white" />
                          </clipPath>
                      </defs>
                  </svg>
            </div>
            <div className="right-column">
            <p> Seat selection </p>
             <p>Your favourite sear every time</p>
            </div>
        </div>
        
      <div className="layout-container">
            <div className="left-column">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                      <g clip-path="url(#clip0_0_155)">
                          <path d="M25 49.7833C38.6874 49.7833 49.7833 38.6874 49.7833 25C49.7833 11.3125 38.6874 0.216614 25 0.216614C11.3125 0.216614 0.216614 11.3125 0.216614 25C0.216614 38.6874 11.3125 49.7833 25 49.7833Z" fill="#0F6CBD" stroke="black" stroke-width="0.5" stroke-miterlimit="10" />
                          <path d="M23.5962 21.6118L29.2114 3.45752M39.3761 33.9255L27.0537 27.4437M25.1127 23.1196C25.7626 23.1196 26.6291 23.7695 26.6291 24.636C26.6291 25.5026 25.7626 26.1525 25.1127 26.1525C24.2461 26.1525 23.5962 25.5026 23.5962 24.636C23.5962 23.7695 24.2461 23.1196 25.1127 23.1196Z" stroke="#3CAEA3" stroke-width="2" stroke-miterlimit="10" />
                      </g>
                      <defs>
                          <clipPath id="clip0_0_155">
                              <rect width="50" height="50" fill="white" />
                          </clipPath>
                      </defs>
                  </svg>
            </div>
            <div className="right-column">
            <p> Seat selection </p>
                <p>Your favourite sear every time</p>
            </div>
        </div>
        
      <div className="layout-container">
            <div className="left-column">
                  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="46" viewBox="0 0 52 46" fill="none">
                      <g clip-path="url(#clip0_0_171)">
                          <path d="M34.069 17.7629H14.3448V4.52674C14.3448 3.02665 13.1793 1.87952 11.6552 1.87952H7.17242V23.3221L9.83518 26.0575L23.9917 30.3549L26.6276 29.2342H36.7586V20.4101C36.7586 18.91 35.5931 17.7629 34.069 17.7629Z" fill="#3CAEA3" />
                          <path d="M6.27588 0.105896C6.81381 0.105896 7.17243 0.45886 7.17243 0.988305V23.3133L9.83519 26.0487M39.6276 30.3725C39.3586 29.6666 38.7311 29.2254 37.9242 29.2254H36.7586M36.7586 29.2254V20.4013C36.7586 18.9012 35.5931 17.7541 34.069 17.7541H14.3448V4.51794C14.3448 3.01785 13.1793 1.87071 11.6552 1.87071H7.17243M36.7586 29.2254H26.6276L23.9917 30.3461" stroke="#3CAEA3" stroke-miterlimit="10" />
                          <path d="M14.3448 23.0397C7.88964 23.0397 2.68964 28.1577 2.68964 34.5111C2.68964 40.8644 7.88964 45.9824 14.3448 45.9824C20.8 45.9824 26 40.8644 26 34.5111C26 28.1577 20.8 23.0397 14.3448 23.0397Z" fill="#0F6CBD" />
                      </g>
                      <defs>
                          <clipPath id="clip0_0_171">
                              <rect width="52" height="46" fill="white" />
                          </clipPath>
                      </defs>
                  </svg>
            </div>
            <div className="right-column">
            <p> Seat selection </p>
                <p>Your favourite sear every time</p>
            </div>
        </div>
    </>
  )
}

export default Whytravel
