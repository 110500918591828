import React from 'react'


const Services = () => {
  return (
    <>


       <section>
        Services Page
       </section>
    </>
  )
}

export default Services
